.super-admin {
  .container {
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
  }

  .super-admin__table {
    th {
      border-top: none;
    }
    td, th {
      vertical-align: middle;
    }
    .material-icons {
      color: $secondary;
      @extend .mr-2;
      cursor: pointer;
    }
  }

  .super-admin__avatar {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 15px 20px;
  }

  .super-admin__featured {
    @extend .mb-4;
  }

  .super-admin__featured-info {
    margin-bottom: 15px;
    margin-top: 15px;
    .btn {
      margin-left: 10px;
    }
  }

  .super-admin__featured-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border: 1px dashed $secondary;
  }
}
