@import "../../assets/bootstrap/variables";

$main-header-height: 140px;

.main-header {
  display: flex;
  min-height: $main-header-height;
  @media (max-width: 1220px) {
    display: none;
  }
  .container {
    position: relative;
  }
  .container > nav {
    display: flex;
    align-items: center;
    background: url(../../assets/header/title-bar.png) no-repeat left 18px;
    position: relative;
    height: 111px;
    margin-left: -39px;
  }
}

.main-header__beta {
  position: absolute;
  right: 20px;
  bottom: 0;
  color: $secondary;
}

.main-header__item, a.main-header__item {
  padding: 0 $grid-gutter-width/3;
  color: $white;
  text-shadow: 0 1px 4px #000000;
  font-family: GWENT, $font-family-base;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &._avatar {
    padding: 0;
    display: block;
    width: 40px;
    margin: 0 10px 0 25px;
  }
}

a.main-header__logo {
  width: 150px;
  height: 52px;
  margin-right: 30px;
  position: relative;
  &::after {
    content: '';
    display: block;
    background: url(../../assets/header/title-bar-logo-en.png);
    width: 288px;
    height: 176px;
    position: absolute;
    top: -65px;
    left: -60px;
    pointer-events: none;
  }
}

.main-header__user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-header__user-notification {
  color: #FFE600;
  cursor: pointer;
  font-size: 32px;
  margin: 0 $grid-gutter-width/4;
}

.main-header__user-name {
}

.main-header__user-avatar {
  margin: 0;
  &._empty {
    margin-top: 5px;
  }
}


.header-mobile {
  background: url(../../assets/header/menu-1280.png) repeat-x;
  height: 112px;
  width: 100%;
  padding: 0 30px;
  display: none;
  @media (max-width: 1220px) {
    display: block;
  }
}

.header-mobile__wrap {
  height: 70px;
  display: flex;
  align-items: center;

  .main-header__user {
    margin-left: auto;
  }
}

.header-mobile__toggle {
  cursor: pointer;
}

.header-mobile__toggle-bar {
  width: 32px;
  height: 4px;
  margin-bottom: 8px;
  background-image: linear-gradient(to right,#fadf7a,#e2a73e);
  filter: drop-shadow(0 1px 2px #000);
  &:last-child {
    margin-bottom: 0;
  }
}

.header-mobile__menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #000;
  z-index: 10;
  top:0;
  left: 0;
  padding: 30px;
  display: none;
  &._active {
    display: block;
  }
}

.header-mobile__close {
  position: fixed;
  top: 25px;
  left: 30px;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.header-mobile__close-bar {
  width: 32px;
  height: 4px;
  background-image: linear-gradient(to right,#fadf7a,#e2a73e);
  filter: drop-shadow(0 1px 2px #000);
  transform-origin: center center;
  &:first-child {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  &:last-child {
    margin-top: -4px;
    transform: rotate(-45deg);
  }
}

a.header-mobile__logo {
  display: block;
  width: 60%;
  max-width: 600px;
  margin: 0 auto 20px auto;
  img {
    width: 100%;
    height: auto;
  }
}

.header-mobile__menu-link {
  display: block;
  font-family: $font-family-gwent;
  text-shadow: 0 1px 4px #000000;
  font-size: 20px;
  text-transform: uppercase;
  color: #D7BE5E;
  margin-bottom: 20px;
}
