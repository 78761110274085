.organization-edit {
  max-width: 578px;
  width: 100%;
  margin: 0 auto;
  border: 22px solid;
  border-image: url(../../assets/border/double.png) 22 round round;
  background-color: #000;
  padding: 14px;
  &-drop_zone {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-textarea {
    background: #131313;
    border: 1px solid #949494;
    border-radius: 2px;
    height: 111px !important;
    resize: none;
    padding: 12px;
    &::placeholder {
      font-style: italic;
      color: #555555;
      font-size: 14px;
    }
  }
  &-label {
    margin-bottom: 5px;
  }
}

.organization-edit__avatar-wrapper {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

.organization-edit__avatar {
  width: 100px;
  height: 100px;
  padding: 5px;
  background-color: $input-bg;
  border: 1px dashed $secondary;
  @extend .mr-3;
  @extend .mb-3;
  @extend .mt-2;
  display: flex;
  justify-content: center;
  align-items: center;
  .material-icons {
    color: $secondary;
    font-size: 64px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.organization-edit__avatar-file {
  position: absolute;
  top: -100px
}

.organization-edit__avatar-title {
  @extend .mt-4;
  @extend .mb-2;
  text-transform: uppercase;
  font-family: $font-family-bold;
  color: $primary;
}

.organization-edit__avatar-hint {

}
