.tournament-page {

  .tournament-bracket__preview {
    @extend .p-2;
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    @extend .mb-3;
  }

  .tournament-bracket__preview-head {
    font-size: 18px;
  }

  .tournament-bracket__preview-error {
    font-size: 18px;
    color: $danger;
  }

  .tournament-bracket__container {
    position: relative;
  }

  .tournament-bracket, .tournament-bracket__sidebar {
    @extend .p-2;
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    min-height: 400px;
  }

  .tournament-bracket__full-screen {
    background: url(../../assets/ico/full-screen/off.png);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;
    z-index: 1;
    &._active {
      background: url(../../assets/ico/full-screen/on.png);
    }
  }

  .tournament-bracket__preview {
    position: relative;
  }

  .tournament-bracket__preview-manual {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .tournament-bracket__seed {
    position: absolute;
    top: 205px;
    left: 220px;
    z-index: 1;
  }

  .tournament-bracket__seed-body {
    background-color: $block-bg;
    border: 1px solid $border-color;
    @extend .p-3
  }

  .tournament-bracket__seed-head {
    font-size: 18px;
    @extend .pb-2
  }

  .tournament-bracket__seed-players {
    display: flex;
  }

  .tournament-bracket__seed-players-head {
    border-bottom: 1px solid $border-color;
    text-transform: uppercase;
    min-width: 100px;
    font-size: 12px;
    padding-bottom: 5px;
    &._1 {
      @extend .mr-3
    }
  }

  .tournament-bracket__seed-players-item {
    @extend .mt-1;
    cursor: pointer;
    .material-icons {
      vertical-align: -3px;
      font-size: 16px;
      color: $secondary
    }
  }

  .tournament-bracket__header {
    border-bottom: 1px solid #292618;
    margin-bottom: 12px;
  }

  .tournament-bracket__round-btn {
    width: 34px;
    height: 34px;
    line-height: 30px;
    border: 1px solid $secondary;
    color: $secondary;
    @extend .mr-1;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    font-family: $font-family-bold;
    border-radius: 2px;
    cursor: pointer;
    @extend .mb-2;
    &._active {
      background-color: $secondary;
      color: $black;
    }
  }

  .tournament-bracket__round-filter {
    @extend .mb-3;
  }

  .tournament-bracket__sidebar-match {
    display: flex;
    align-items: flex-start;
    padding:12px;
    &._edit {
      outline: 1px dashed $secondary;
    }
  }

  .tournament-bracket__sidebar-match-number {
    color: #424242;
    font-family: $font-family-bold;
    font-size: 18px;
    margin-right: 12px;
    margin-top: 20px;
  }

  .tournament-bracket__sidebar-start-round {
    width: 100%;
    margin-bottom: 20px;
  }
}

@import "./brackets/spectators";
