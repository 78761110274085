.tournament-page {
  > section {
    position: relative;
  }

  .tournament-header {
    position: relative;
    min-height: 285px;
    display: flex;
    align-items: flex-end;
    padding: $grid-gutter-width;
    margin-bottom: $grid-gutter-width/2;
    background: $block-bg no-repeat center center;
    background-size: cover;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top:0;
      bottom: 0;
      left:0;
      right:0;
      border: 12px solid;
      border-image-source: url(../../assets/border/silver-b.png);
      border-image-slice: 12;
      border-image-outset: 0;
      border-image-repeat: round;
    }

    &._featured {
      &::after {
        border-image-source: url(../../assets/border/gold-b.png);
      }
      .tournaments-header__remained {
        background-image: url(../../assets/button/gold-active.png);
      }
    }
  }

  .tournaments-header__remained {
    position: absolute;
    left: $grid-gutter-width;
    top: $grid-gutter-width;
    background: url(../../assets/button/silver-active.png) no-repeat;
    background-size: 100% 100%;
    min-width: 90px;
    min-height: 25px;
    text-transform: uppercase;
    font-family: 'GWENT', $font-family-base;
    text-shadow: 0 0 1px #FFFFFF;
    color: #000;
    font-size: 18px;
    padding: 3px 10px;
  }

  .tournament-header__date {
    font-family: $font-family-bold;
    text-align: center;
    padding-right: $grid-gutter-width;
    text-shadow: 0 2px 3px #000000;
  }

  .tournament-header__date-month {
    font-size: 24px;
    text-transform: uppercase;
    line-height: 13px;
  }

  .tournament-header__date-day {
    font-size: 48px;
    line-height: 56px;
  }

  .tournament-header__name {
    font-size: 36px;
    font-family: $font-family-bold;
    color: $primary;
    text-shadow: 0 2px 3px #000000;
    line-height: 1;
    margin-bottom: 5px;
  }

  .tournament-header__time {
    font-size: 24px;
    text-shadow: 0 2px 3px #000000;
  }

  .tournament-user {
    background-color: $block-bg;
    margin-bottom: $grid-gutter-width/2;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
  }

  .tournament-user__info {
    display: flex;
  }

  .tournament-user__avatar {
    margin: 15px 25px 20px 20px;
  }

  .tournament-user__name {
    font-family: GWENT, $font-family-base;
    font-size: 18px;
    line-height: 1;
    padding-top: 8px;
  }

  .tournament-user__ranking {
    margin-left: auto;
    display: flex;
    font-family: GWENT, $font-family-base;
    font-size: 18px;
    > div {
      margin-right: $grid-gutter-width/2;
    }
  }

  .tournament-user__ranking-wdl {
    display: flex;
    div {
      margin-right: $grid-gutter-width/2;
      &:last-child {
        margin-right: 0;
      }
    }
    span {
      color: $secondary;
    }
  }

  .tournament-user__matches {
    display: flex;
  }

  .tournament-user__matches-group {
    padding: $grid-gutter-width/2 0;
  }

  .tournament-user__matches-group-name {
    font-family: $font-family-bold;
    margin-left: $grid-gutter-width/2;
  }

  .tournament-user__matches-group-list {
    display: flex;
    flex-wrap: wrap;
    .bracket__match {
      margin-left: $grid-gutter-width/2;
      margin-bottom: $grid-gutter-width/2;
    }
  }

  .tournament-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 50px;
    //padding-right: $grid-gutter-width;
    margin-bottom: $grid-gutter-width/2;
    .btn {
      margin-left: $grid-gutter-width/3;
      @media (max-width: 400px) {
        margin-bottom: $grid-gutter-width/3;
      }
    }
  }

  .tournament-nav__item {
    padding: $grid-gutter-width/2 $grid-gutter-width/3;
    font-family: GWENT, $font-family-base;
    font-size: 18px;
    color: $white;
    &.active {
      color: $primary;
    }
  }



}

@import "tournament-bracket-page";
@import "tournament-description-page";
@import "tournament-participant-page";
@import "tournament-match-page";
@import "tournament-logs";
