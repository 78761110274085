$b_space: 96px;
$b_height: 32px;
$b_round_max: 8;
$b_match_width: 200px;
$b_score_width: 32px;
$l_space: 16px;

@function pow($number, $exp) {
  @if ($exp == 0) {
    @return 1;
  }
  @if ($exp == 1) {
    @return $number;
  }

  $value: 1;

  @for $i from 1 through $exp {
    $value: $value * $number;
  }

  @return $value;
}

.bracket-single {
  position: relative;
  &._third {
    &._4 {
      padding-bottom: $b_height * 2 + 50 + $grid-gutter-width/2;
    }
    &._8 {
      padding-bottom: 30 + $grid-gutter-width/2;
    }
  }
  &._2 {
    padding-bottom: $b_height * 8;
  }

  &._edit {
    padding-bottom: 250px;
  }
}

.bracket__final {
  padding-right: $grid-gutter-width;
  display: flex;
}

.bracket__winner {
  display: flex;
}

.bracket__round {
  padding-left: $l_space * 2;
  &._0 {
    padding-left: 0;
  }

}

.bracket__round-name {
  font-family: $font-family-bold;
}

.bracket__round-format {
  margin-bottom: $grid-gutter-width/2;
  color: $secondary;
}

.bracket__round-start {
  height: 25px;
  margin-bottom: $grid-gutter-width/2;

}

.bracket__round-games {
  padding-top: 1px;
}

@function calcSpacer($round) {
  @return $b_height * (pow(2, $round) - 1)  + $b_space * (pow(2, $round - 1) - 1 ) + $b_space / 2;
}

.bracket__match, a.bracket__match {
  position: relative;
  display: block;
  color: $white;
  text-decoration: none;

  &::after {
    content: ' ';
    height: $b_height;
    width: $l_space;
    border-bottom: 1px solid #949494;
    display: block;
    position: absolute;
    top: 0;
    left: -$l_space;
    .bracket__round._0 & {
      display: none
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    border: 1px solid #949494;
    border-left: none;
    width: $l_space;
    left: -$l_space * 2;

    top: -$b_space / 2 - 1;
    height: $b_height * 2 + $b_space + 1;

    .bracket__round._0 & {
      display: none;
    }
  }
}

.bracket__match._single {

  .bracket__round._0 & {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $b_space;
  }

  @mixin create-spacer($round) {
    .bracket__round._#{$round} & {
      $sp: calcSpacer($round);
      &:first-child {
        margin-top: $sp;
      }
      margin-top: $sp * 2 + $b_space;
    }
  }

  @for $i from 1 through $b_round_max {
    @include create-spacer($i);
  }

  &::before {
    @mixin create-connector($round) {
      .bracket__round._#{$round} & {
        top: -$b_height * (pow(2, $round - 1) - 1) - $b_space * pow(2, $round - 2) - 1;
        height: $b_height * pow(2, $round) + $b_space * pow(2, $round - 1) + 1;
      }
    }

    @for $i from 2 through $b_round_max {
      @include create-connector($i);
    }
  }
}

.bracket__player {
  width: $b_match_width;
  border: 1px solid #424242;
  height: $b_height;
  line-height: $b_height;
  display: flex;
  &._2 {
    border-top: none;
  }
  &._winner {
    color: $primary;
  }
  .material-icons {
    vertical-align: middle;
  }
  &._seed {
    cursor: pointer;
    border-left-style: dashed;
    border-right-style: dashed;
    &._1 {
      border-top-style: dashed;
    }
    &._2 {
      border-bottom-style: dashed;
    }
    .material-icons {
      color: $secondary;
    }
    .bracket__player-name {
      color: $secondary;
    }
    &:hover {
      background-color: $nav-active-bg;
    }
  }
  &._spectator {
    background-color: #292618;
  }
  .bracket__player-spectator-ico {
    color: $primary;
    font-size: 16px;
  }
}

.bracket__player-name {
  width: $b_match_width - $b_score_width - 2;
  padding: 0 8px;
}

.bracket__player-score {
  width: $b_score_width;
  padding-right: 8px;
  text-align: right;
  .material-icons {
    cursor: pointer;
  }
}

.bracket__third {
  position: absolute;
  @mixin create-third($round) {
    &._#{$round} {
      left: ($b_match_width + $l_space * 2) * $round;
      top: calcSpacer($round) + $b_height * 2 + 60 + $b_space * 2;
    }
  }

  @for $i from 1 through $b_round_max {
    @include create-third($i);
  }

  &._0 {
    top:200px;
  }
}

.bracket-double {
  display: flex;
  padding-bottom: 30px;

  $i: 1;
  @while ($i < $b_round_max) {
    $players: pow(2, $i + 2);
    .bracket-single._#{$players} {
      margin-left: $b_match_width * $i + $l_space * 2 * $i;
    }
    $i: $i + 1;
  }
}

.bracket__loser {
  display: flex;
  margin-top: $grid-gutter-width;
  &._edit {
    margin-bottom: 250px;
  }
}

.bracket__match._double {
  .bracket__round._0 & {
    &:first-child {
      margin-top: $b_height;
    }
    margin-top: $b_space;
  }

  .bracket__round._1 & {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $b_space;
  }

  $i: 1;
  $j: 2;
  @while ($j <= $b_round_max * 1.5) {
    .bracket__round._#{$j} & {
      $sp: calcSpacer($i);
      &:first-child {
        margin-top: $sp - $b_height * ($i - 1);
      }
      margin-top: $sp * 2 + $b_space;
    }

    $i: $i + 1;
    $j: $j + 2;
  }

  $i: 1;
  $j: 3;
  @while ($j <= $b_round_max * 1.5) {
    .bracket__round._#{$j} & {
      $sp: calcSpacer($i);
      &:first-child {
        margin-top: $sp - $b_height * $i;
      }
      margin-top: $sp * 2 + $b_space;
    }

    $i: $i + 1;
    $j: $j + 2;
  }

  &::before {
    top: $b_height;
    height: $b_height;

    $i: 1;
    @while ($i <= $b_round_max * 1.5) {
      .bracket__round._#{$i} & {
        border-top: none;
      }
      $i: $i + 2;
    }

    $i: 2;
    $j: 1;
    @while ($i <= $b_round_max*2) {
      .bracket__round._#{$i} & {
        top: -$b_height * (pow(2, $j - 1) - 1) - $b_space * pow(2, $j - 2) - 1;
        height: $b_height * pow(2, $j) + $b_space * pow(2, $j - 1) + 1;
      }
      $i: $i + 2;
      $j: $j + 1;
    }

    .bracket__round._2 & {
      top: -$b_space/2 - 1;
    }
  }
}

.bracket__match._final::before {
  @mixin create-connector($round) {
    .bracket__round._#{$round} & {
      height: $b_height * pow(2, $round - 1) + $b_space * pow(2, $round - 2) + $grid-gutter-width + 48 + $grid-gutter-width/2 + calcSpacer($round - 2) - $b_space / 2 - $b_height * ($round - 3) + 2;
    }
  }

  @for $i from 3 through $b_round_max {
    @include create-connector($i);
  }

  .bracket__round._2 & {
    $round: 2;
    height: $b_height * pow(2, $round - 1) + $b_space * pow(2, $round - 2) + $grid-gutter-width + 48 + $grid-gutter-width/2 + $b_height * pow(2, $round - 2) - $b_space/2 + 2;
  }
}

.bracket__match._final2::before {
  display: none;
}

.round-robin__table {
  thead th {
    color: $secondary;
    font-weight: normal;
    border: none;
    div {
      cursor: help;
    }
  }
  td {
    border: none;
  }
}

.round-robin__table-number, .round-robin__table-w, .round-robin__table-d, .round-robin__table-l, .round-robin__table-plus-minus {
  width: 40px;
}

.round-robin__table-tb, .round-robin__table-pts {
  width: 50px;
}

.round-robin__table-number, .round-robin__table-plus-minus, .round-robin__table-tb {
  color: $secondary;
}

.round-robin__table-pts {
  color: $primary;
}

.round-robin__table-matches-toggle {
  text-align: right;
}

.round-robin__total-advancing {
  border: 1px solid $primary;
}

td.round-robin__table-matches {
  padding-left: 0;
  padding-right: 0;
  .bracket__match {
    vertical-align: top;
    display: inline-block;
    @extend .mr-2;
    @extend .mb-2;
  }
}

.round-robin__seeding {
  display: flex;
  flex-wrap: wrap;
}

.round-robin__seeding-group {
  @extend .mr-3;
  min-height: 350px;
}

.bracket__round._round-robin {
  margin-right: $b_space;
}

.bracket__match._round-robin {
  margin-bottom: 0;
  &::before, &::after {
    display: none;
  }
}

.swiss__total-advancing {
  font-family: $font-family-bold;
}

.swiss__total-eliminating {
  text-decoration: line-through;
}

.swiss__games {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $grid-gutter-width;
}

.bracket__round._swiss .bracket__round-games {
  display: flex;
  flex-direction: column-reverse;
}

.bracket__match._swiss{
  margin-bottom: $b_space;
  margin-right: $b_space;
}

.tournament-bracket__seed {
  &._single, &._double {
    @for $i from 1 through 32 {
      &._m#{$i}._p0 {
        padding-top: $b_space * $i + $b_height * 2 * $i - $b_height * 0.5;
      }
      &._m#{$i}._p1 {
        padding-top: $b_space * $i + $b_height * 2 * $i + $b_height * 0.5;
      }
    }
  }
  &._round-robin {
    top: 170px;
    @for $i from 1 through 10 {
      &._b#{$i} {
        left: 205 + $b_match_width * $i + 20 * $i;
      }
    }
  }
}

.bracket__match {
  &._single, &._double {
    .bracket__player._spectator {
      position: absolute;
      &._2 {
        bottom: -$b_height*2;
      }
    }
  }
}


.bracket__spectators {
  background: $block-bg;
  border: 22px solid;
  border-image: url(../../assets/border/double.png) 22 round round;
  width: 360px;
  position: absolute;
  z-index: 2;
  top: -22px;
  left: $b_match_width + 25;
}

.bracket__spectators-title {
  color: $primary;
  font-size: 18px;
  text-transform: uppercase;
}

.bracket__spectators-row {
  display: flex;
}

.bracket__spectators-col {
  &:first-child {
    margin-right: 32px;
  }
}

.bracket__spectators-col-title {
  color: $secondary;
}

.bracket__spectators-col-item {
  margin-top: 12px;
  cursor: pointer;
}

.bracket__match-edit-ico {
  color: $secondary;
  position: absolute;
  left: $b_match_width;
  top: 0;
  cursor: pointer;
  padding: 20px 3px;
  z-index: 1;
  display: flex;
}
