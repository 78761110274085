.tournament-logs {
  @extend .p-2;
  background: $block-bg;
  border: 22px solid;
  border-image: url(../../assets/border/double.png) 22 round round;
}

.tournament-logs__item {
  margin-bottom: $grid-gutter-width/3;
  display: flex;
  flex-wrap: nowrap;
}

.tournament-logs__highlight {
  color: $primary;
  &._click {
    cursor: pointer;
  }
}

.tournament-logs__time {
  width: 200px;
  margin-right: $grid-gutter-width/3;
}
.tournament-logs__message {
  flex: 1;
}

.tournament-logs__problem {
  button {
    float: right;
  }
}

.tournament-logs__problem-resolved {
  color: $secondary;
  float: right;
}

.tournament-logs__filter {
}

.tournament-logs__hr {
  border-top: 1px solid #292618;
  margin-top: 24px;
  margin-bottom: 24px;
}
