@import "variables";
@import "~react-perfect-scrollbar/dist/css/styles.css";

@font-face {
  font-family: 'GWENT';
  src: url('/fonts/gwent/GWENT-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('/fonts/gwent/GWENT-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/gwent/GWENT-ExtraBold.woff') format('woff'), /* Modern Browsers */
  url('/fonts/gwent/GWENT-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/gwent/GWENT-ExtraBold.svg#GWENT-ExtraBold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HalisGR-Regular';
  src: url('/fonts/HalisGR/310E27_B_0.eot');
  src: url('/fonts/HalisGR/310E27_B_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisGR/310E27_B_0.woff2') format('woff2'),
  url('/fonts/HalisGR/310E27_B_0.woff') format('woff'),
  url('/fonts/HalisGR/310E27_B_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisGR-Bold';
  src: url('/fonts/HalisGR/310E27_4_0.eot');
  src: url('/fonts/HalisGR/310E27_4_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisGR/310E27_4_0.woff2') format('woff2'),
  url('/fonts/HalisGR/310E27_4_0.woff') format('woff'),
  url('/fonts/HalisGR/310E27_4_0.ttf') format('truetype');
}

@font-face {
  font-family: 'HalisGR-RegularItalic';
  src: url('/fonts/HalisGR/310E27_9_0.eot');
  src: url('/fonts/HalisGR/310E27_9_0.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HalisGR/310E27_9_0.woff2') format('woff2'),
  url('/fonts/HalisGR/310E27_9_0.woff') format('woff'),
  url('/fonts/HalisGR/310E27_9_0.ttf') format('truetype');
}

html {
  height: 100%;
  font-size: 14px;
}

label {
  margin-bottom: 0;
}

body {
  height: 100%;
  background: #121315 repeat-y center top;
  background-image: url(../back/bg-top-mid-netdecking-961a8d2b.jpg), url(../back/bg-seamless-middle-netdecking-653f1e06.jpg);
  background-repeat: no-repeat, repeat;
  background-position: top, center 659px
}

b, strong {
  font-weight: normal;
  font-family: $font-family-bold;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main-container {
  flex: 1;
  padding-bottom: $grid-gutter-width;
  background: url(../../assets/back/bg-footer-new.png) no-repeat center bottom;
  background-size: contain;
  min-height: 1000px;
}

.btn {
  height: 25px;
  padding: 5px 26px;
  line-height: 17px;
  font-size: 14px;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
  font-family: $font-family-gwent;
  text-shadow: 0 0 1px #FFFFFF;
}

.btn-primary {
  background: url(../button/gold-active.png) no-repeat;
  background-size: 100% 100%;
  &:hover, &:focus {
    background-image: url(../button/gold-focus.png);
    background-color: transparent;
    color: $black;
  }
  &:active, &.active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    background-image: url(../button/gold-press.png);
    background-color: transparent;
    color: $black;
  }
  &:disabled, &.disabled {
    background-image: url(../button/gold-disabled.png);
    background-color: transparent;
    color: #A4700C;
  }
}

.btn-secondary {
  background: url(../button/silver-active.png) no-repeat;
  background-size: 100% 100%;
  color: $black;
  &:hover, &:focus {
    background-image: url(../button/silver-focus.png);
    background-color: transparent;
    color: $black;
  }
  &:active, &.active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    background-image: url(../button/silver-press.png);
    background-color: transparent;
    color: $black;
  }
  &:disabled, &.disabled {
    background-image: url(../button/silver-disabled.png);
    background-color: transparent;
    color: #7D7D7D;
  }
}

.btn-light {
  background: url(../button/outline-active.png) no-repeat;
  background-size: 100% 100%;
  color: $white;
  &:hover, &:focus {
    background-image: url(../button/outline-focus.png);
    background-color: transparent;
    color: $white;
  }
  &:active, &.active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    background-image: url(../button/outline-press.png);
    background-color: transparent;
    color: $white;
  }
  &:disabled, &.disabled {
    background-image: url(../button/outline-disabled.png);
    background-color: transparent;
    color: #424242
  }
}

.btn-dark {
  background: url(../button/outline-dark.png) no-repeat;
  background-size: 100% 100%;
  color: $black;
  &:hover, &:focus {
    background-image: url(../button/outline-dark.png);
    background-color: transparent;
    color: $black;
  }
  &:active, &.active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    background-image: url(../button/outline-dark.png);
    background-color: transparent;
    color: $black;
  }
  &:disabled, &.disabled {
    background-image: url(../button/outline-dark.png);
    background-color: transparent;
    color: $black;
    opacity: .5;
  }
}

.btn-kings {
  background: url("../button/button-abouts.png") no-repeat;
  background-size: 100% 100%;
}

.btn-fancy {
  background: url(../button/button-fancy-active.png);
  width: 264px;
  height: 57px;
  line-height: 45px;
  color: $black;
  font-family: $font-family-gwent;
  font-size: 18px;
  text-shadow: none;
  &:hover, &:focus {
    background: url(../button/button-fancy-focus.png);
    color: $black;
  }
  &:active, &.active, &:not(:disabled):not(.disabled):active, &:not(:disabled):not(.disabled).active {
    background: url(../button/button-fancy-press.png);
    color: $black;
  }
  &:disabled, &.disabled {
    background: url(../button/button-fancy-disabled.png);
    color: #A4700C;
  }
}

.avatar {
  background-size: cover;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
  &._empty {
    background-image: url(../../assets/avatar/avatar.png);
  }
  .avatar__border {
    position: absolute;
    left: -80%;
    top: -80%;
    width: 260%;
    height: 260%;
    background-size: cover;
    &._empty {
      background-image: url(../../assets/avatar/avatar-frame.png);
      left: -40%;
      top: -40%;
      width: 160%;
      height: 160%;
    }
  }
}

.input-group-text {
  background: linear-gradient(359.03deg, #C4C4C4 2.44%, #F1F1F1 99.17%);
  .material-icons {
    font-size: 21px;
  }
  .ico-svg {
    width: 21px;
    height: 21px;
    fill: $input-group-addon-color;
  }
}

label, legend {
  font-family: $font-family-bold;
  margin-bottom: 5px;
}

label.form-check-label {
  text-transform: none;
  font-size: $font-size-base;
  font-family: $font-family-base;
}

input, button, select, optgroup, textarea {
  font-family: $font-family-base;
}

.form-group .toggle-btn-group {
  display: block;
  .btn {
    text-transform: uppercase;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    width: 50%;
  }
}

h1, h2 {
  color: $primary;
  text-transform: uppercase;
}

h3 {
  color: $white;
  text-transform: uppercase;
}

.user-search {
  position: relative;
}

.user-search__list {
  position: absolute;
  border: 1px solid $border-color;
  border-radius: $input-border-radius;
  width: 100%;
  z-index: 1;
}

.user-search__list-item {
  border-bottom: 1px solid $border-color;
  @extend .px-2;
  @extend .py-1;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: $nav-active-bg;
    cursor: pointer;
  }
}

.switch {
  background: url("../ico/switch/off.png");
  width: 29px;
  height: 17px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  &._active {
    background: url("../ico/switch/on.png");
  }
}

.badge-filter {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: normal;
  @extend .mr-2;
  @extend .mb-2;
  cursor: pointer;
  &.badge-secondary {
    background-color: #454545;
  }
}

.arrow-btn {
  display: inline-block;
  background-color: $primary;
  width: 30px;
  height: 30px;
  text-align: center;
  color: $black;
  border-radius: 2px;
  cursor: pointer;
  padding-top: 3px;
  user-select: none;
  &:hover {
    background-color: #F3C23C;
  }
  &:active {
    background-color: #C38900;
  }
}

.tournament-hint {
  display: flex;
  align-items: center;
  border-top: 1px solid #292618;
  border-bottom: 1px solid #292618;
  @extend .py-3;
  .material-icons {
    color: #3890FF;
    margin-right: $grid-gutter-width/3;
    font-size: 44px;
  }
}

.tooltip-inner {
  background: #E5A830;
}

.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #E5A830;
}
