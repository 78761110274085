a.tournaments__card {
  background: $block-bg;
  margin-bottom: $grid-gutter-width;
  display: block;
  color: inherit;
  text-decoration: none;
  position: relative;
  &._featured {
    .tournaments__card-remained {
      background-image: url(../../assets/button/gold-active.png);
    }
    .tournaments__card-name {
      font-size: 36px;
    }
    &::after {
      border-image-source: url(../../assets/border/gold-b.png);
    }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    border: 12px solid;
    border-image-source: url(../../assets/border/silver-b.png);
    border-image-slice: 12;
    border-image-outset: 0;
    border-image-repeat: round;
  }
}

.tournaments__card-image {
  height: 180px;
  background: $block-bg no-repeat center center;
  background-size: cover;
  position: relative;
}

.tournaments__card-image-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: url(../../assets/tournament-image-bottom.png) repeat-x;
  height: 30px;
}

.tournaments__card-remained {
  position: absolute;
  left: 24px;
  top: 24px;
  background: url(../../assets/button/silver-active.png) no-repeat;
  background-size: 100% 100%;
  min-width: 90px;
  min-height: 25px;
  text-transform: uppercase;
  font-family: 'GWENT', $font-family-base;
  text-shadow: 0 0 1px #FFFFFF;
  color: #000;
  font-size: 18px;
  padding: 3px 10px 3px 10px;
}

.tournaments__card-body {
  padding: 0 24px 24px 24px;
}

.tournaments__card-name {
  margin-bottom: $grid-gutter-width/3;
  font-size: 20px;
  height: 60px;
  font-family: $font-family-bold;
  color: $primary;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tournaments__card-info {
  display: inline-block;
  width: 50%;
  font-size: 12px;
  > .material-icons {
    font-size: 16px;
    color: $secondary;
    margin-right: $grid-gutter-width/4;
    vertical-align: sub;
    &._closed {
      color: #E10000;
    }
    &._open {
      color: #00E841;
    }
  }
}

.tournaments__card-host {
  margin-top: 10px;
  display: flex;
  align-items: center;
  span {
    color: $link-color;
  }
}

.tournaments__card-host-avatar {
  margin: 20px 25px 15px 15px;
}

img.tournaments__card-host-avatar {
  width: auto;
  max-width: 65px;
  height: 45px;
  margin: 0 10px 0 0;
  object-fit: contain;
}

.tournaments__card-bottom {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #292618;
  display: flex;
  position: relative;
  z-index: 2;
}
