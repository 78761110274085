.t-img-select {
  $img-height: 285px;
  position: relative;
  .t-img-select__current {
    border: 1px dashed $secondary;
    background-color: $input-bg;
    background-size: cover;
    height: 147px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .t-img-select__list {
    position: absolute;
    top: 147px;
    border: 1px dashed $secondary;
    width: 100%;
    height: $img-height*1;
    overflow-x: hidden;
    overflow-y: auto;
    background: $input-bg;
    z-index: 3;
  }
  .t-img-select__img {
    width: 100%;
    height: auto;
    margin-bottom: 1px;
    cursor: pointer;
  }
}
