@import "tournament-image-select";

.tournament-edit {
  max-width: 762px;
  width: 100%;
  margin: 0 auto;
  background: #000000;
  border: 22px solid;
  border-image: url(../../assets/border/double.png) 22 round round;
  padding: 15px 30px 0 30px;
  &-title {
    font-family: "GWENT", $font-family-base;
    font-size: 24px;
    margin-bottom: 6px;
  }
  &-description {
    color: $white;
    margin-bottom: 12px;
  }
  &-form_group {
    margin-bottom: 24px;
    &_label {
      margin-bottom: 19px;
    }
  }
  &-contacts {
    flex-direction: column;
  }
}

.tournament-editor {

  .tournament-editor__toolbar {
    margin-bottom: 0;
    border-top-right-radius: $input-border-radius;
    border-top-left-radius: $input-border-radius;
    border: 1px solid none;
    padding: 0;
    >div {
      margin-bottom: 0;
    }
  }

  .rdw-option-wrapper {
    margin: 0;
    padding: 12px;
  }

  .tournament-editor__main {
    min-height: 150px;
    background-color: $input-bg;
    color: $input-color;
    border: 1px solid $input-border-color;
    border-top: none;
    padding: $input-padding-y $input-padding-x;
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: $input-border-radius;
  }
}

.tournament-edit__contact-btn {
  margin-top: 26px;
  height: 30px;
}

.tournament-edit__btn-row {

}

.tournaments-edit__admins {
  display: flex;
  @extend .pl-2;
  @extend .mt-3;
}

.tournaments-edit__admin-item {
  @extend .mr-3;
  .btn {
    padding: 0;
    @extend .ml-1;
    width: 24px;
    height: 24px;
  }
  .material-icons {
    font-size: 16px;
    margin-top: 3px;
  }
}

.tournament-edit__buttons {
  > * {
    margin-bottom: 10px;
  }
}

.tournament-edit__date-update {
  padding: 3px 5px;
  transition: all 1s ease 0s;

  &._active {
    background-color: $primary;
  }

  &._error {
    background-color: $danger;
  }
}

.tournament-edit__tz {
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 2;
}
