.tournament-page {
  .tournament-description {
    display: flex;
    flex-wrap: wrap;
  }

  .tournament-description__text {
    background-color: $block-bg;
    padding: 15px;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    flex: 2;
    min-width: 360px;
    margin-bottom: 20px;
    @media (min-width: 990px) {
      margin-right: 15px;
    }
  }

  .tournament-description__winner {
    font-size: 18px;
    font-family: $font-family-gwent;
    color: $primary;
    margin-bottom: 20px;
  }

  .tournament-info {
    background-color: $block-bg;
    min-width: 368px;
    padding: 15px;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    flex: 1;
  }

  .tournament-host {
    margin-bottom: $grid-gutter-width;
    .material-icons {
      margin-left: $grid-gutter-width/3;
      font-size: 18px;
      color: $secondary;
    }
  }

  .tournament-host__title {
    display: flex;
    margin-bottom: $grid-gutter-width/2;
  }

  .tournament-host__avatar {
    width: 30px;
    height: 30px;
    margin: 10px 25px 15px 10px
  }

  img.tournament-host__avatar {
    width: auto;
    max-width: 65px;
    height: 45px;
    margin: 0 10px 0 0;
    object-fit: contain;
  }

  .tournament-host__name {
    font-size: 18px;
    font-family: GWENT, $font-family-base;
    color: $primary;
  }

  .tournament-host__contact {
    margin-left: 5px;
    display: inline-block;
  }

  .tournament-host__contacts {
    margin-bottom: $grid-gutter-width/2;
    .btn {
      width: 32px;
      height: 32px;
      padding: 3px 4px;
    }
  }

  .tournament-host__admin {
    display: flex;
    align-items: center;
    margin-top: $grid-gutter-width/3;
    font-size: 18px;
    color: $primary;
    font-family: GWENT, $font-family-base;
    .material-icons {
      margin-left: $grid-gutter-width/3;
      font-size: 18px;
      color: $secondary;
    }
  }

  .tournament-host__admin-avatar {
    margin-right: $grid-gutter-width/3;
  }

  .tournament-details__title {
    font-size: 18px;
    margin-bottom: $grid-gutter-width/2;
  }

  .tournament-details__item {
    margin-bottom: $grid-gutter-width/3;
  }

  .tournament-details__players {
    margin-top: $grid-gutter-width;
  }
}
