.organization-page {

  .organization-page__head {
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    margin-bottom: $grid-gutter-width/2;
    display: flex;
    @extend .p-2;
    @extend .mb-4;
  }

  .organization-page__avatar {
    width: 55px;
    height: 55px;
    @extend .mr-3;
  }

  img.organization-page__avatar {
    width: auto;
    max-width: 65px;
    object-fit: contain;
  }

  .organization-page__name {
    color: $primary;
    font-size: 18px;
  }

  .organization-page__contacts {
    display: flex;
    a {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #6c757d;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
  }

  .organization-page__description-wrapper {
    display: flex;
    flex: 2;
    justify-content: center;
    align-items: center;
    color: #949494;
  }

  .organization-page__description {
    max-width: 500px;
  }

  .organization-page__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @extend .mb-4;
  }

  .organization-page__nav-item {
    height: 72px;
    line-height: 72px;
    color: #fff;
    font-family: 'GWENT', $font-family-base;
    font-size: 18px;
    padding: 0 10px;
    &.active {
      color: $primary;
    }
  }

  .organization-page__nav-button {
    margin-left: auto;
    margin-right: 20px;
    @media (max-width: 360px) {
      margin-left: 0;
    }
  }

  .organization-page__members {
    background: $block-bg;
    position: relative;
    padding: 20px 20px 20px 30px;
    display: flex;
    flex-wrap: wrap;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 12px solid;
      border-image-source: url(../../assets/border/silver-b.png);
      border-image-slice: 12;
      border-image-outset: 0;
      border-image-repeat: round;
    }
  }

  .organization-page__members-list {
    width: 350px;
    margin-bottom: 20px;
  }

  .organization-page__members-head {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 30px;
  }

  .organization__member-item {
    display: flex;
    align-items: center;
    color: $primary;
    margin-bottom: 20px;
  }

  .organization__member-avatar {
    width: 30px;
    height: 30px;
    margin: 10px 25px 15px 10px
  }

  .organization__member-remove {
    cursor: pointer;
    z-index: 1;
  }

  .user_avatar_blo {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 69px 0 21px;
  }

  .user_avatar_blo > p {
    font-size: 14px;
    line-height: 20px;
    color: #C3AD59;
    text-shadow: 0 1px 4px #000000;
    margin: 0 21px 0 6px;
  }

  .organization__members-block-footer_button {
    padding: 30px 22px;
    z-index: 1;
    > button {
      width: 100%;
      padding: 5px 0;
      z-index: 1;
    }
  }

  .organization__members-main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .organization__members-block {
    max-width: 355px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: black;
    margin-right: 15px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top:0;
      bottom: 0;
      left:0;
      right:0;
      border: 12px solid;
      border-image-source: url(../../assets/border/silver-b.png);
      border-image-slice: 12;
      border-image-outset: 0;
      border-image-repeat: round;
    }
  }

  .organization__members-block-head {
    width: 100%;
    padding: 21px 23px 27px 21px;
  }

  .organization__members-block-header {
    width: 100%;
    > h3 {
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    > p {

      font-size: 14px;
      line-height: 20px;
      color: #949494;
    }
  }

  .create__user_daa {
    margin-top: 12px;
    position: relative;
    z-index: 100;
    > p {
      font-size: 14px;
      line-height: 16px;

      color: #FFFFFF;
      margin: 0;
    }
    > input {
      width: 100%;
      font-size: 14px;
      line-height: 16px;

      color: #949494;
      background: #131313;
      border: 1px solid #949494;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 7px 11px;
      margin-bottom: 12px;
      margin-top: 6px;
    }
  }

  .owner__block {
    max-width: 355px;
    position: relative;
    background: black;
    margin-bottom: 20px;
    margin-right: 15px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top:0;
      bottom: 0;
      left:0;
      right:0;
      border: 12px solid;
      border-image-source: url(../../assets/border/silver-b.png);
      border-image-slice: 12;
      border-image-outset: 0;
      border-image-repeat: round;
    }
  }

  .owner__block_title {
    padding: 25px 23px 0 23px;
  }

  .owner__block_title > h3 {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF;

  }

  .owner__block_title > p {

    font-size: 14px;
    line-height: 20px;
    color: #949494;
    padding: 0;
  }

  .onwer__input_block {
    width: 100%;
    height: 100%;
    padding: 24px 23px 0 23px;
    margin-bottom: 52px;

    > p {
      font-size: 14px;
      line-height: 16px;

      color: #FFFFFF;
      padding: 0;
    }

    > input {
      width: 100%;
      font-size: 14px;
      line-height: 16px;

      color: #949494;
      background: #131313;
      border: 1px solid #949494;
      box-sizing: border-box;
      border-radius: 2px;
      padding: 7px 11px;
      margin-bottom: 12px;
      margin-top: 6px;
      position: relative;
      z-index: 100;
    }

    > div {
      height: 25px;
      width: 100%;
      display: flex;
      align-items: center;
    }

    > div > button {
      border: none;
      background: linear-gradient(270deg, #A5A5A5 0%, #ECECEC 100%);
      font-family: $font-family-gwent;
      font-size: 18px;
      text-align: center;
      color: #000000;
      text-shadow: 0 0 1px #FFFFFF;
      height: 100%;
      width: 100%;
      z-index: 10;
    }
  }

  .about__block {
    max-width: 370px;
    margin-bottom: 20px;
    height: 100%;
    padding: 32px 31px 42px 32px;
    background: url("../../assets/Members-image/Double.png") no-repeat;
  }

  .about__title > h2 {
    font-size: 18px;
    line-height: 26px;

    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #D7BE5E;
  }

  .about__table_titles {
    width: 100%;

    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    > h3 {
      width: 55%;
      font-size: 12px;
      line-height: 20px;

      text-transform: uppercase;
      color: #FFFFFF;
      border-right: 1px solid #292618;
      border-bottom: 1px solid #292618;
      margin: 0;
      padding-bottom: 6px;
    }

    > div {
      width: 55%;

      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      text-align: center;

      > h3 {
        width: 50%;
        font-size: 12px;
        line-height: 20px;

        text-transform: uppercase;
        color: #FFFFFF;
        border-right: 1px solid #292618;
        border-bottom: 1px solid #292618;
        margin: 0;
        padding-bottom: 6px;

      }

      > h3:nth-child(2) {
        border-right: none;
      }
    }
  }

  .what__can_block {
    width: 100%;
    display: flex;
  }

  .wat__can_text {
    width: 50%;
    border-right: 1px solid #292618;
    padding-top: 6px;
    > p {

      font-size: 14px;
      line-height: 20px;
      color: #949494;
      margin: 0;
    }
  }

  .wat__can_checkead > div > img {
    width: 14px;
    height: 14px;
    margin-bottom: 7px;
  }

  .no_chek {
    width: 14px;
    height: 14px;
    margin-bottom: 7px;
  }

  .chekit__or_not {
    width: 50%;
    display: flex;
  }

  .wat__can_checkead {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: center;
    padding-top: 12px;
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 14px;
    }
  }

  .wat__can_no_checkead {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: center;
    padding-top: 12px;
    border-left: 1px solid #292618;
    > div {

      display: flex;

      flex-direction: column;
      margin-bottom: 14px;
    }
  }
}
