.main-page {
  background-color: transparent;

  .tournament-search {
    input, select {
      border-color: #262110;
      background-color: transparent;
      &:focus {
        border-color: $primary;
      }
      ::-webkit-input-placeholder {
        color: #44392a;
      }
      ::-moz-placeholder {
        color: #44392a;
      }
      :-ms-input-placeholder {
        color: #44392a;
      }
      :-moz-placeholder {
        color: #44392a;
      }
    }
    svg {
      fill: $primary;
    }
  }


  .tournaments {
  }

}
