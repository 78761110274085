.tournament-page {
  .tournament-participants {
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    @extend .p-2;
  }

  .tournament-participants__table {
    th {
      border-top: none;
    }
    td, th {
      vertical-align: middle;
    }
    .material-icons {
      color: $secondary;
      @extend .mr-2;
      cursor: pointer;
    }
    input {
      margin-bottom: 5px;
    }
    i[disabled] {
      cursor: not-allowed;
    }
  }

  .participant-contact {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
  }

  #participants__add, #participants__block, #participants__search {
    max-width: 327px;
    padding: 5px 10px;
  }

  .participant__search-wrap {
    margin-top: 31px;
    margin-bottom: 30px;
  }

  .tournament-participants__blocked-item {
    display: inline-block;
    color: $primary;
    @extend .mr-2;
    @extend .mb-2;
    @extend .mt-2;
    .material-icons {
      color: $secondary;
      @extend .ml-1;
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .participant-avatar, .tournament-participants__blocked-avatar {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    margin: 15px 20px;
  }

  .participant-avatar {
    margin-left: 0;
  }

  .participant-leader {
    display: inline-block;
    width: 32px;
    height: 32px;
    border: 1px solid #424242;
    margin-right: $grid-gutter-width/3;
  }

  .participant-action {
    font-size: 16px;
    margin-right: $grid-gutter-width/3;
  }

  .participant-toggle-decks {
    cursor: pointer;
    display: inline-block;
    margin-right: 15px;
    vertical-align: top;
  }
}
