.tournament-search {
  background: $block-bg;
  border: 22px solid;
  border-image: url(../../assets/border/double.png) 22 round round;
  margin-bottom: $grid-gutter-width/2;
  @extend .px-2;
  label {
    text-transform: uppercase;
    font-size: 12px;
  }
  .material-icons {
    font-size: 18px;
    color: $secondary;
  }
  .input-group-text {
    background: #000;
  }
  svg {
    fill: #f0e7c4;
    width: 18px;
    height: 18px;
  }
}
