@font-face {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    src: url('fonts/AhmetAltunHalisGRMedium.eot');
    src: url('fonts/AhmetAltunHalisGRMedium.eot') format('embedded-opentype'),
    url('fonts/AhmetAltunHalisGRMedium.woff2') format('woff2'),
    url('fonts/AhmetAltunHalisGRMedium.woff') format('woff'),
    url('fonts/AhmetAltunHalisGRMedium.ttf') format('truetype'),
    url('fonts/AhmetAltunHalisGRMedium.svg#AhmetAltunHalisGRMedium') format('svg');
}

.border__images {
    width: 100%;
    height: 100%;
    position: absolute;
}

.text_st {
    color: #E5A62B;
}

.game__button {
    cursor: pointer;
}

.silver {
    position: absolute;
    top: 20px;
    left: 20px;
}

.border__im {
    position: absolute;
    width: 100%;
    height: 100%;
}

.increase__block {
    width: 300px;
    max-width: 400px;
    background: url("./increase/border.png") no-repeat;
    background-size: contain;
    height: 500px;
    z-index: 10;
    padding: 36px 29px;
    position: absolute;
    top: 95px;
    left: 49%;
}

.increase__header1 {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.increase__title {
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    color: #FFFFFF;
}

.coment__blovk {

    display: flex;
    flex-direction: column;
}

.info__block {
    display: flex;
    margin-top: 24px;
    cursor: pointer;
}

.ball__block {
    margin-right: 24px;
}

.red_ball {
    width: 11px;
    height: 11px;
    border: 1px solid #FF0000;
    background: #FF0000;
    border-radius: 50%;
}

.info__text {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 20px;
}

.info__text span {
    color: #D7BE5E;
}

.tex {
    color: #949494;
    margin-bottom: 23px;
}

.buttn_config {
    width: 16px;
    height: 16px;
    display: flex;
    padding: 6px 4px;
    border: 1px solid white;
    border-radius: 3px;
    background: black;
    color: white;
}

.increase__footer_bootn {

    display: flex;
}

.button__block {

    display: flex;
    align-items: center;
}

.button__block_text {
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    margin-left: 11px;
    margin-bottom: 0;
    margin-right: 29px;
}

.increase__block_footer {
    margin-top: 22px;

    display: flex;
    justify-content: space-between;
}

.scroll_b {
    background-color: black;
    height: 369px;
    width: 240px;
    overflow-y: scroll;
}

.scroll_b::-webkit-scrollbar {
    width: 8px;
    background-color: #000056;
}

.scroll_b::-webkit-scrollbar-thumb {
    background-color: #636363;
    border-radius: 4px;
}

.scroll_b::-webkit-scrollbar-track {
    background: #232323;
    border-radius: 4px;
}

.cup__block {
    width: 276px;
    height: 124px;
    background: url("./increase/cupbackround.png") no-repeat;
    background-size: contain;
    position: absolute;
    top: 96px;
    left: 76%;
    z-index: 100;
    padding: 21px 34px;

    display: flex;
}

.cup_info {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 16px;
    line-height: 20px;
    color: #000;
    font-weight: normal;
}

.info_decoration {
    text-decoration-line: underline;
    color: black;
    font-size: 16px;
    line-height: 20px;
    font-family: "HalisGR-Bold";
}

.increase__header1 > div > button {
    border: none;
    background: none;
}

.exit__btn {
    width: 14px;
    height: 14px;
    color: #1C1C1C;
    font-family: "HalisGR-Bold";
}

.game__pages_block {
    width: 100%;
    height: 900px;

    display: flex;
    flex-direction: column;

}

.game__pages_header {
    width: 100%;
    height: 48%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
}

.game__block {
    width: 49%;
    height: 100%;
    position: relative;
}

.game__pages_footer {
    width: 100%;
    height: 42%;

    display: flex;
    justify-content: space-between;
}

.game__iamges_block {
    width: 100%;
    height: 50%;
    position: relative;
}

.game__iamges_block > img {
    width: 100%;
    height: 100%;
    position: absolute;
}

.border_game_header {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
}

.game__info {
    width: 100%;
    height: 50%;

    display: flex;
    flex-direction: column;
    padding-right: 37px;
    padding-left: 24px;
    background: black;
}

.game__info_title {
    ont-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 48px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
}

.game__info_text {
    margin-bottom: 40px;
}

.game_pages_footer {
    width: 100%;

    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    margin-right: 55px;
}

.game__user_block {
    width: 31%;

    display: flex;
    flex-direction: column;
}

.game__user__info {
    width: 100%;

    display: flex;
    align-items: center;

}

.game__user__info > p {
    margin: 0;
    margin-left: 13px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
}

.game__user_block_avatar {
    width: 31%;

    display: flex;
}

.game__user_block_avatar > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
}

.game__user_block_avatar > p > span {
    color: #D7BE5E;
}

.control_block {
    width: 69%;
}

.first_info {
    width: 26%;
}

.game__footer_block {
    width: 32%;
    height: 100%;
    position: relative;
    background: black;
}

.game__footer_block_images {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 1000;
}

.game__footer_block_images_header {
    width: 100%;
    height: 50%;
    position: relative;
}

.game__footer_block_images_header > img {
    width: 100%;
    height: 100%;
    position: absolute;
}

.game__footer_text_block {
    width: 100%;
    height: 50%;

    display: flex;
    flex-direction: column;
    padding-right: 37px;
    padding-left: 24px;
    background: black;
}

.game__footer_title {
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    color: #D7BE5E;
}

.user_infot_date {

    display: flex;
    align-items: center;

}

.distantia {
    margin-right: 52px;
}

.user_infot_date > p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    margin-left: 13px;

}

.user_infot_date > img {
    margin: 0;
}

.user_indo_block_footer {
    width: 100%;

    display: flex;
}

.im {
    width: 10px;
    height: 10px;
}

.user_block_avatar {
    width: 100%;

    display: flex;
    margin-top: 22px;
    align-items: center;
}

.user_block_avatar > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    margin: 0 0 0 21px;
}

.user_block_avatar > p > span {
    color: #D7BE5E;
}

.header__block {
    background: #000000;
    margin-bottom: 24px;
    padding: 3px 10px;
    background-size: contain;
    border: 22px solid;
    border-image: url("./edit-organisation/headerborder.png") 22 round round;
    width: 100%;

    display: flex;
    justify-content: space-between;
    /*padding: 25px 99px 38px 34px;*/
    align-items: center;
}

.text_block {
    width: 54%;
}

.user__block {
    width: 35%;

    display: flex;
}

.site__icon_block {

    display: flex;
    justify-content: space-between;
}

.site__icon_block > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #6c757d;

    display: flex;
    align-items: center;
    justify-content: center;
}

.site__icon_block > div > img {

    height: 12px;
}

.user__name_block > h3 {
    color: #D7BE5E;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
}

.user__info_block {
    margin-left: 12px;
}

.text_block > p {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 20px;
    color: #949494;
}

.turnaments__block {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.tournament__block_main {
    width: 33%;
    position: relative;
    background: black;
}

.border__ima {
    position: absolute;
    width: 100%;
    height: 100%;
}

.info__title_user_block {
    width: 100%;
    padding: 10px 27px 22px 19px;
}

.info_title > h2 {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    color: #D7BE5E
}

.info__user_block {
    width: 100%;

    display: flex;
    flex-direction: column;
    margin-top: 34px;
}

.info__user_block_info {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.user_block_chek > div {

    display: flex;
    align-items: center;
}

.user_block_chek > div > p {
    margin: 0 0 0 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
}

.user__avatar_block {
    width: 100%;

    display: flex;
    align-items: center;
    margin-top: 22px;
}

.user__avatar_block > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    margin: 0 0 0 22px;
}

.user__avatar_block > p > span {
    color: #E5A62B;
}

.line_div {
    width: 100%;
    border: 1px solid #292618;
    margin: 24px 0 24px 0;
}

.button__group {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.left_btn {
    width: 81%;

    display: flex;
    justify-content: space-between;
}

.edit_btn {

    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 10;

}

.edit_btn > button {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #000;
    height: 100%;
    background: linear-gradient(270deg, #E5A62B 0%, #E7CD79 100%);
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: GWENT;

}

.edit_btn2 {

    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

.edit_btn2 > button {
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 16px;
    height: 100%;: linear-gradient(270 deg, #E5A62B 0 %, #E7CD79 100 %);
    border: none;
    outline: none;
    cursor: pointer;
    font-family: GWENT
}

.cursore_btn {
    position: relative;
    z-index: 10;
    cursor: pointer;
}

.cursore_btn > button {
    background: #D7BE5E;
    border-radius: 2px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
}

.navigation__manue {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.navigation__manue > nav {
    width: 40%;
}

.navigation__manue > nav > ul {
    width: 100%;

    display: flex;
    justify-content: space-between;
}

.navigation__manue > nav > ul > li {
    list-style-type: none;
}

.navigation__manue > nav > ul > li:nth-child(1) a {
    list-style-type: none;
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    color: #D7BE5E;
}

.navigation__manue > nav > ul > li > a {
    text-decoration: none;
    color: white;
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
}

.right__menu_button {

    display: flex;
}

.right__menu_button {
    margin-right: 27px;
    margin-bottom: 10px;
}

.right__menu_button > button {
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    color: black;
    text-align: center;
    border: none;
    outline: none;
    background: linear-gradient(270deg, #E5A62B 0%, #E7CD79 100%);
}

.user_data_btn {
    width: 100%;

    display: flex;
}

.user_data_btn > button {
    width: 100%;
    background: linear-gradient(270deg, #E5A62B 0%, #E7CD79 100%);
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    text-shadow: 0px 0px 1px #FFFFFF;
    border: none;
    outline: none;
}

.bt {
    border: none;
    background: none;
    position: relative;
}

.rdw-fontsize-option {
    color: black;
}

.demo-option-custom > img {
    width: 14px;
    height: 10px;
}

.demo-option-custom_photo > img {
    width: 14px;
    height: 14px;
}

a:not([href]) {
    color: #949494 !important;
}

.rdw-image-modal {
    background: #1C1C1C;
}

.iconClosed {
    display: flex;
    align-items: flex-end;
}

.iconClosed img {
    margin-bottom: 7px;
}

.main_left_btn {

    display: flex;
    justify-content: space-between;
}

.main_edit_btn {

    display: flex;
    cursor: pointer;
    z-index: 200;
}

.main_edit_btn > button {
    border: 1px solid #95E5BA;
    box-sizing: border-box;
    background: rgba(252, 252, 252, 0);
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #95E5BA;
    border-left: none;
    border-right: none;
    text-shadow: 0px 1px 4px #000000;
    display: flex;
    align-items: center;
}

.main_edit_btn_2 {

    display: flex;
    align-items: center;
    background: linear-gradient(270deg, #A5A5A5 0%, #ECECEC 100%);
}

.btnChecked {
    margin: 1px 0;
}

.btnChecked img {
    margin-right: 7px;
}

.main_edit_btn_2 {

    display: flex;
    cursor: pointer;
    z-index: 200;
    background: none;
    border: none;
}

.main_edit_btn_2 > button {
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    border: none;
    color: #000000;
    height: 100%;
    text-shadow: 0px 0px 1px #FFFFFF;
    background: linear-gradient(270deg, #A5A5A5 0%, #ECECEC 100%);
    outline: none;
}

.main_edit_btn_header {
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    cursor: pointer;
}

.main_edit_btn_header > button {
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    border: none;
    color: #000000;
    text-shadow: 0px 0px 1px #FFFFFF;
    background: linear-gradient(270deg, #A5A5A5 0%, #ECECEC 100%);
    outline: none;
}

.content_info_button {
    cursor: pointer;
    z-index: 100;
}

.content_info_button > Link {
    background: none;
    border: none;
    outline: none;
    outline: none;
}

.sp {
    display: flex;
    justify-content: space-between;
}

.block__players_block {
    width: 100%;
    position: relative;
    background: black;
}

.block__players_block > img {
    position: absolute;
    width: 100%;
}

.block__players_content {
    padding: 29px 0 27px 21px;
}

.block__players_warning {

    display: flex;
    margin-bottom: 34px;
}

.block__players_warning > img {
    margin-right: 10px;
}

.block__players_warning > p {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 20px;
    color: #949494;
    margin: 0;
}

.block__players_change > h3 {
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
}

.block__players_change > input {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 16px;
    color: #949494;
    background: #131313;
    border: 1px solid #949494;
    box-sizing: border-box;
    border-radius: 2px;
    width: 320px;
    padding: 7px 11px;
}

.menue {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.menue > nav {
    width: 60%;
}

.right__menu_button_nav {

    display: flex;
}

.right__menu_button_nav {
    margin-right: 27px;
    margin-bottom: 10px;
}

.right__menu_button_nav > button {
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    color: black;
    text-align: center;
    border: none;
    outline: none;
    background: linear-gradient(270deg, #E5A62B 0%, #E7CD79 100%);
}

.block__players_button {

    display: flex;
    justify-content: flex-end;
    width: 29.5%;
    margin-top: 12px;
}

.block__players_change_button {

    display: flex;
}

.block__players_change_button > button {
    font-family: GWENT;
    font-size: 16px;
    line-height: 14px;
    color: #000000;
    text-shadow: 0px 0px 1px #FFFFFF;
    text-align: center;
    border: none;
    outline: none;
    background: linear-gradient(270deg, #E5A62B 0%, #E7CD79 100%);
}

.block_footer_button {
    width: 20%;

    display: flex;
    align-items: center;
    padding: 30px 22px
}

.block_footer_button > button {
    background: black;
    font-family: GWENT;
    font-size: 18px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 1px 4px #000000;
    border: 1.5px solid #535353;
    height: 27px;
    border-left: none;
    border-right: none;
    width: 100%;
    z-index: 10;

}

.members__admin__block {
    width: 100%;
    position: relative;
}

.members__admin__block > img {
    width: 100%;
    height: 100%;
    position: absolute;
}

.members__blck {
    width: 100%;
    display: flex;
    background: black;
    padding: 21px 23px 27px 23px;
}

.admins__blck {
    width: 40%;
}

.admins__blck > h2 {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF
}

.admin__images__block {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;
}

.admin__images__block > p {
    font-size: 14px;
    line-height: 20px;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    color: #C3AD59;
    text-shadow: 0px 1px 4px #000000;
    margin: 0 21px 0 6px;
}

.admin__images__block > button {
    background: rgba(255, 255, 255, 0);
    border: none;
    z-index: 10;
}

.owner__blck > h2 {
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #FFFFFF;
    /*font-family: 'AhmetAltunHalisGRMedium';*/
}

.measge__block {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: absolute;
    top: 110%;
    left: 35%;
}

.measge__block > button {
    display: flex;
    align-items: center;
    background: #E5A830;
    border-color: #E5A830;
    padding: 6px 11px 4px 14px;
}

.measge__block > button > p {
    /*font-family: 'AhmetAltunHalisGRMedium';*/
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
}

.measge__block > button > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.none {
    display: none;
}

.unread {
    width: 19px;
    height: 19px;
    background: #FF0000;
    border: 3px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px #000000;
    border-radius: 50%;
    position: absolute;
    left: 28px;
    top: 4px
}

.nounread {
    display: none;
}
