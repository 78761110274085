.main-footer {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;
  margin-top: auto;
  background: transparent url(../../assets/footer/line-footer-gold.png) repeat-x top;
  a {
    display: block;
    color: $body-color;
    &._active {
      color: $primary;
    }
  }
}

a.main-footer__logo {
  margin-right: $grid-gutter-width*2;
  color: $secondary;
  font-family: $font-family-bold;
  font-size: 24px;
  text-decoration: none;
  display: block;
  width: 120px;
  img {
    width: 100%;
  }
}

.main-footer__copy {
  border-top: 1px solid $secondary;
  margin-top: $grid-gutter-width;
  padding-top: $grid-gutter-width;
  text-align: center;
  color: #606364;
}

.main-footer__cdpr-logo-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-footer__cdpr-logo {

  &.__gwent {
    background: url(../../assets/footer/gwent.png);
    width: 110px;
    height: 58px;
    margin-top: 15px;
  }
  &.__gog {
    background: url(../../assets/footer/gog.png);
    width: 186px;
    height: 42px;
    margin-top: 28px;
  }
  &.__cdpr {
    background: url(../../assets/footer/cdpr.png);
    width: 193px;
    height: 83px;
    margin-right: 0;
    margin-top: 0;
  }
}
