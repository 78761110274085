.dropzone {
    height: 147px;
    width: 100%;
    background-color: #131313;
    border-radius: 2px;
    border: 1px dashed rgb(187, 186, 186);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.Icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
}

.FileInput {
    display: none;
}