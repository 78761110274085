.tournament-page {
  .match__lobby {
    position: relative;
    @extend .p-2;
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
  }

  .match__sidebar {
    @extend .p-2;
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    .btn {
      width: 100%;
    }
  }

  .match__logs {
    @extend .mb-2;
  }

  .match__logs-time {
    color: $secondary;
  }

  .match__lobby-players {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @extend .mb-3;
  }

  .match__lobby-avatar {
    margin: 15px 25px 15px 20px;
  }

  .match__lobby-player {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &:last-child {
      text-align: right;
      margin-left: auto;

      .match__lobby-avatar {
        margin-left: 35px;
        margin-right: 10px;
      }
    }
  }

  .match__lobby-player-name {
    font-family: GWENT, $font-family-base;
    font-size: 18px;
    color: $primary;
    margin-right: 10px;
  }

  .match__lobby-player-online {
    color: #E10000;
    &._active {
      color: #009A22;
    }
  }

  .match__lobby-decks {
    display: flex;
    justify-content: space-between;
    @extend .mb-4;
  }

  .match__lobby-leaders {
    display: flex;
    flex-wrap: wrap;
    //max-width: 270px;
    //min-width: 210px;
    width: 100%;
    padding-top: 21px;
    //margin-left: -3px;
    //margin-right: -3px;
    &._second {
      justify-content: flex-end;
    }
  }

  .match__lobby-leader {
    margin: 0 3px 6px 3px;
    background-color: #000;
    border: 1px solid $secondary;
    width: 48px;
    height: 64px;
    position: relative;
    cursor: pointer;

    &._ban {
      border: 2px solid #E10000;
      .material-icons, .match__lobby-ban {
        display: block;
      }
    }

    .material-icons {
      position: absolute;
      color: #E10000;
      font-size: 28px;
      top: 50%;
      left: 50%;
      margin-left: -14px;
      margin-top: -14px;
      display: none;
    }

}

  .match__lobby-ban {
    position: absolute;
    color: #E10000;
    bottom: -2px;
    display: none;
    font-size: 12px;
    text-align: center;
    width: 100%;
    text-shadow: 0 2px 3px #000000;
  }

  .match__lobby-match-score {
    @extend .mx-2;
    text-align: center;
  }

  .match__lobby-match-score-numbers {
    display: flex;
    flex-wrap: wrap;
  }

  .match__lobby-match-score-number {
    border: 2px solid #424242;
    color: #424242;
    font-family: GWENT, $font-family-base;
    font-size: 42px;
    text-align: center;
    width: 48px;
    height: 64px;
    &:first-child {
      margin-right: 5px;
    }
    &._win {
      border-color: $primary;
      color: $primary;
    }
  }

  .match__lobby-actions {
    &._second {
      text-align: right;
    }
  }

  .match__lobby-actions-timer {
    .material-icons {
      font-size: 22px;
      margin-top: -3px;
      vertical-align: middle;
    }
  }

  .match__details {
    thead th {
      border: none;
      font-weight: normal;
    }
    tbody tr:first-child td {
      border-top: none;
    }
    td {
      vertical-align: middle;
      &:first-child {
        padding-left: 0;
      }
    }
    ._1 {
      text-align: right;
    }
    ._win {
      color: $primary;
    }
  }

  .match__detail-name {
  }

  .match__detail-score {
  }

  .match__detail-col {
    width: 100px;
  }

  .match__detail-leader {
    width: 32px;
  }

  .match__detail-leader-avatar {
    width: 32px;
    height: 32px;
    border: 1px solid #424242;
    &._win {
      border-color: $primary;
    }
  }

  .match__detail-result {
    width: 50px;
  }

  .match__detail-coin {

  }

  .match__detail-coin-item {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid;
    text-align: center;
    font-family: $font-family-gwent;
    &._c1 {
      color: $primary;
      border-color: $primary;
    }
    &._c2 {
      color: $secondary;
      border-color: $secondary;
    }
  }

  .match__lobby-coinflip-wrapper {
    position: relative;
  }
}
