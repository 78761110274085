.organisations-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.organisations-page__info {
  width: 360px;
  background: #000000;
  margin-bottom: 20px;
  margin-right: 15px;
  padding: 3px 10px;
  background-size: contain;
  border: 22px solid;
  border-image: url("../../assets/border/double.png") 22 round round;
}

.organisations-page__info-title {
  font-size: 20px;
  line-height: 26px;
  color: #D7BE5E;
}

.organisations-page__info-text {
  font-size: 14px;
  line-height: 20px;
  color: #949494;
}

.organisations-page__card {
  height: 200px;
  width: 350px;
  position: relative;
  background: black;
  margin-right: 15px;
  margin-bottom: 20px;
  padding: 26px 13px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    border: 12px solid;
    border-image-source: url(../../assets/border/silver-b.png);
    border-image-slice: 12;
    border-image-outset: 0;
    border-image-repeat: round;
  }
}

.organisations-page__card-info {
  display: flex;
}

.organisations-page__card-avatar {
  margin-right: 10px;
  max-width: 65px;
  width: auto;
  height: 60px;
  object-fit: contain;
}

.organisations-page__card-data {
  z-index: 1;
  text-decoration: none;
  overflow: hidden;
  &:hover {
    text-decoration: none;
  }
}

.organisations-page__card-name {
  color: $primary;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 4px;
}

.organisations-page__card-count {
  display: flex;
  align-items: center;
  p {
    margin: 0;
    color: #949494;
    font-size: 14px;
    line-height: 20px;
  }
  > img {
    margin-right: 8px;
  }
}

.organisations-page__card-line {
  border: 1px solid #292618;
  margin: 24px 0;
}

.organisations-page__card-buttons {
  display: flex;
  justify-content: space-between;
  z-index: 1;
  position: relative;
}

