.leader-ability {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  &._122105 {
    background-image: url(../leaders/small_122105.png)
  }
  &._131101 {
    background-image: url(../leaders/small_131101.png)
  }
  &._132103 {
    background-image: url(../leaders/small_2_132103.png)
  }
  &._200055 {
    background-image: url(../leaders/small_3_200055.png)
  }
  &._200159 {
    background-image: url(../leaders/small_28_200159.png)
  }
  &._200160 {
    background-image: url(../leaders/small_200160.png)
  }
  &._200161 {
    background-image: url(../leaders/small_200161.png)
  }
  &._200162 {
    background-image: url(../leaders/small_10_200162.png)
  }
  &._200163 {
    background-image: url(../leaders/small_200163.png)
  }
  &._200164 {
    background-image: url(../leaders/small_200164.png)
  }
  &._200165 {
    background-image: url(../leaders/small_24_200165.png)
  }
  &._200166 {
    background-image: url(../leaders/small_200166.png)
  }
  &._200167 {
    background-image: url(../leaders/small_200167.png)
  }
  &._200168 {
    background-image: url(../leaders/small_200168.png)
  }
  &._200170 {
    background-image: url(../leaders/small_200170.png)
  }
  &._201580 {
    background-image: url(../leaders/small_201580.png)
  }
  &._201589 {
    background-image: url(../leaders/small_201589.png)
  }
  &._201595 {
    background-image: url(../leaders/small_15_201595.png)
  }
  &._201597 {
    background-image: url(../leaders/small_30_201597.png)
  }
  &._201743 {
    background-image: url(../leaders/small_201743.png)
  }
  &._202115 {
    background-image: url(../leaders/small_202115.png)
  }
  &._202116 {
    background-image: url(../leaders/small_202116.png)
  }
  &._202117 {
    background-image: url(../leaders/small_202117.png)
  }
  &._202118 {
    background-image: url(../leaders/small_202118.png)
  }
  &._202119 {
    background-image: url(../leaders/small_202119.png)
  }
  &._202148 {
    background-image: url(../leaders/small_202148.png)
  }
  &._202185 {
    background-image: url(../leaders/small_202185.png)
  }
  &._202186 {
    background-image: url(../leaders/small_202186.png)
  }
  &._202187 {
    background-image: url(../leaders/small_202187.png)
  }
  &._202188 {
    background-image: url(../leaders/small_202188.png)
  }
  &._202189 {
    background-image: url(../leaders/small_202189.png)
  }
  &._202303 {
    background-image: url(../leaders/small_202303.png)
  }
  &._202328 {
    background-image: url(../leaders/small_202328.png)
  }
  &._202347 {
    background-image: url(../leaders/small_202347.png)
  }
  &._202373 {
    background-image: url(../leaders/small_202373.png)
  }
  &._202390 {
    background-image: url(../leaders/small_32_202390.png)
  }
  &._202572 {
    background-image: url(../leaders/small_202572.png)
  }
  &._202573 {
    background-image: url(../leaders/small_202573.png)
  }
  &._202574 {
    background-image: url(../leaders/small_202574.png)
  }
  &._202575 {
    background-image: url(../leaders/small_202575.png)
  }
  &._202576 {
    background-image: url(../leaders/small_202576.png)
  }
  &._202577 {
    background-image: url(../leaders/small_202577.png)
  }
}
