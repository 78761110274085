.spectators {
  max-width: 300px;
  padding: 8px 0;
  position: relative;

  &._border {
    border-top: 1px solid #292618;
    border-bottom: 1px solid #292618;
    margin: 24px 0;
  }

  .spectators__head {
    display: flex;
    color: $primary;
  }

  .spectators__title {
    text-transform: uppercase;
    font-size: 18px;
  }

  .spectators__toggle {
    margin-left: auto;
    cursor: pointer;
  }

  .spectators__form {
    position: relative;
    margin-top: 18px;
  }

  .spectators__list-item {
    display: flex;
    align-items: center;
    color: $primary;
    margin-top: 12px;
  }

  .spectators__list-avatar {
    border-radius: 50%;
    border: 1px solid $primary;
  }

  .spectators__list-item-remove {
    color: $secondary;
    cursor: pointer;
    margin-left: 5px;
  }

  .spectators-popup {
    position: absolute;
    top: 50px;
    background: $block-bg;
    border: 22px solid;
    border-image: url(../../assets/border/double.png) 22 round round;
    width: 360px;
    z-index: 2;
  }
}
